/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var firstMenuItemPos = $('li.menu-item:first-child a').offset().left;

        $('#nav-line').css('left', firstMenuItemPos+'px');

        var hasActiveNav = $('.nav-primary li.menu-item.current-menu-item').length;

        if(hasActiveNav > 0 ){
          var activeMenuItemPos = $('li.current-menu-item a').offset().left;
          var activeMenuItemWidth = $('li.current-menu-item a').width();

          $('#nav-line').css('left', activeMenuItemPos+'px');
          $('#nav-line').css('width', activeMenuItemWidth+'px');
        }
        
        $('li.menu-item').on('mouseover',function(){
          var linePos = $(this).find('a').offset().left;
          var lineWidth = $(this).find('a').width();
          $('#nav-line').animate({
            left: linePos+'px',
            width: lineWidth+'px'
          }, 100 , function(){
          });
        });

        $('ul.nav').on('mouseleave',function(){
          var newlinePos;
          var newlineWidth;
          if(hasActiveNav > 0 ){
            newlinePos = $('li.current-menu-item a').offset().left;
            newlineWidth = $('li.current-menu-item a').width();
            $('#nav-line').animate({
              left: newlinePos+'px',
              width: newlineWidth+'px'
            }, 100 , function(){
            });
          } else {
            newlinePos = firstMenuItemPos;
            newlineWidth = 0;
            $('#nav-line').animate({
              width: newlineWidth+'px'
            }, 100 , function(){
            });
          }          
        });

        $('#models-close').click(function(){
          $('#models').hide();
        });

        $(document).on('click','#notice-close', function(){
          $('.woocommerce-notices-wrapper').html('');
          $('.woocommerce-NoticeGroup-checkout').html('');
        });

        if($('#models').length > 0 ){
          $(document).mouseup(function(e) 
          {
              var container = $("#models");
              var allCars = $('#all-accessories');
              var carToggle = $('#car-toggle');
              // if the target of the click isn't the container nor a descendant of the container
              if(!carToggle.is(e.target) || allCars.is(e.target)){
                if (!container.is(e.target) && container.has(e.target).length === 0){
                  $('#models').hide();
                  if(!$('body').hasClass('page-template-template-accessories')){
                    $('#car-toggle').closest('li.menu-item').removeClass('current-menu-item');
                  }
                }
              }              
          });

          $(document).on('click','#all-accessories' , function(){
            $('#models').hide();
          });
          
        }

        //Toggle The Cars Menu
        $(document).on('click','#car-toggle',function(){
          $('.model-class').removeClass('active');
          $('.model-range-group').removeClass('active');
          $('.model-class:first-child').addClass('active');
          $('.model-range-group:first-child').addClass('active');
          $('#models').toggle();
          if(!$('body').hasClass('page-template-template-accessories')){
            $(this).closest('li.menu-item').toggleClass('current-menu-item');
          }
        });
        //Change which car range is visible
        $(document).on('click','.model-range-toggle',function(){
          var activeRange = $(this).attr('data-id');
          if($(window).width > 768 ){
            $('.model-class').removeClass('active');
            $(this).closest('.model-class').addClass('active');
            $('.model-range-group').hide();
            $('#'+activeRange).show();
          } else {
            if($(this).closest('.model-class').hasClass('active')){
              $(this).closest('.model-class').removeClass('active');
              $('#'+activeRange).hide();
            } else {
              $('.model-class').removeClass('active');
              $(this).closest('.model-class').addClass('active');
              $('.model-range-group').hide();
              $('#'+activeRange).show();
            }
          }
        });

        $(document).on('click','#search-button',function(){
          $('#searchform-wrap').slideToggle(300);
        });

        $(document).on('click','#search-close',function(){
          $('#searchform-wrap').slideToggle(300);
        });

        $(document).on('click','#sort-toggle',function(){
          $('#product-sort').toggle();
        });



        $('a#toggle-info').click(function(){
          $('#details-modal ').show();
        });

        $('#details-modal #notice-close').click(function(){
          $('#details-modal ').hide();
        });

        function checkMobile(){
          var groupModel;
          var carToggle = $('#car-toggle').html();
          if($(window).width() < 769 ){
            $('body').addClass('mobile');
            $('nav.nav-primary').appendTo('#mobile-menu-container');
            $('ul#user-menu').appendTo('#mobile-menu-container');
            $('#car-toggle').html('Acessórios <i class="fas fa-angle-right"></i>');
            $('.model-range-group').each(function(){
              groupModel = $(this).attr('id');
              $(this).appendTo('.model-class[data-range="'+groupModel+'"');
            });
            $('#first-footer-row .col-md-1').insertBefore('#second-footer-row .col-md-5.footer-pad');
            $('#second-footer-row .col-md-6').insertAfter('#first-footer-row .col-md-3');
          } else {
            $('body').removeClass('mobile');
            $('nav.nav-primary').appendTo('#desktop-menu-container');
            $('ul#user-menu').insertBefore('#cart-action');
            $('#car-toggle').html('Acessórios');
            $('.model-range-group').each(function(){
              groupModel = $(this).attr('id');
              $(this).appendTo('#model-range-wrap > .row');
            });
            $('#first-footer-row .col-md-1').insertBefore('#first-footer-row .col-md-8.footer-pad');
            $('#second-footer-row .col-md-6').appendTo('#second-footer-row > .row');
          }
        }

        $(document).on('click','#nav-toggle-button',function(){
          $('body').toggleClass('show-menu');
        });

        $('a#car-close').click(function(){
          $('#models').toggle();
        });

        //Setup the Mobile Views of the Project
        $(document).ready(checkMobile);
        $(window).on('resize',checkMobile);
        
        if($(window).width() < 769){
          $('#details-content > div').addClass('swiper-wrapper');
          $('#details-content > div').removeClass('row');
          $('#details-content .detail-row').addClass('swiper-slide');
          $('#details-content .detail-row').removeClass('col-md-4');
        }
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        if($(window).width() < 769){
          var detailsSwiper = new Swiper('#details-content', {
            autoplay: {
              delay: 3000,
            },
            speed: 600,
            slidesPerView: 1,
            loop:true,
            pagination: {
              el: '#details-block .pagination',
              type: 'bullets',
              clickable: true
            }
          });
        }
      }
    },
    'page_template_template_accessories_models': {
      init: function() {
          function currentModel(model){
            var selected = $(model).val();
            var curOption = $(model).find('option[value='+selected+']').attr('data-url');
            $(model).next('a.car-select').attr('href' , curOption);
          }
          $(window).load(function(){
            $('.child-variant').each(function(){
              currentModel(this);
            });
          });

          $('.child-variant').change(function(){
            currentModel(this);
          });
      }
    },
    'page_template_template_legal': {
      init: function() {
        $('.page-nav-inner a').click(function(){
          var targetSection = $(this).attr('data-target');
          $('.page-nav-inner').removeClass('active-section-nav');
          $('.page-section').removeClass('active-section');
          $(this).closest('li').addClass('active-section-nav');
          $('#'+targetSection).addClass('active-section');
          $([document.documentElement, document.body]).animate({
            scrollTop: $('#'+targetSection).offset().top
          }, 200);
        });
      }
    },
    'search_no_results': {
      init: function() {
        $('button#show-search').click(function(){
          $('#searchform-wrap').slideToggle(300);
          var headerHeight = $('header.banner').height();
          $([document.documentElement, document.body]).animate({
            scrollTop: 0
          }, 200);
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        function swiperSwipe(){
          if($(window).width() > 768 ){
            $('#banner').addClass('swiper-no-swiping');
          }else {
            $('#banner').removeClass('swiper-no-swiping');
          }
        }
        //Create the Homepage Banner Slideshow
        var testSwiper = new Swiper('#banner', {
          autoplay: {
            delay: 5000,
          },
          speed: 600,
          slidesPerView: 1,
          loop:true,
          noSwipingClass: 'swiper-no-swiping',
          noSwiping: true,
          breakpoints: {
            2200: {
              noSwiping: true,
            },
            768: {
              noSwiping: false,
            },
          },
          pagination: {
            el: '#banner .swiper-pagination',
            type: 'bullets',
            clickable: true
          }
        });

        swiperSwipe();

        $(window).resize(function(){
          swiperSwipe();
        });

        if($(window).width() < 768 ){
          $('#types-slider > div').addClass('swiper-wrapper');
          $('#types-slider > div').removeClass('row');
          $('#types-slider .product-wrap-slide').addClass('swiper-slide');
          $('#types-slider .product-wrap-slide').removeClass('col-md-4');
          
        }

        $('.type-photo').on('mouseover',function(){
          var thisVid = $(this).find('video.family-video');
          //$(thisVid).css('opacity',1);
          $(this).addClass('active');
          $(thisVid).get(0).play();
        });
    
        $('.type-photo').on('mouseout',function(){
          var thisVid = $(this).find('video.family-video');
          //$(thisVid).css('opacity',0);
          $(this).removeClass('active');
          $(thisVid).get(0).pause();
        });
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        if($(window).width() < 769 ){
          var typesSwiper = new Swiper('#types-slider', {
            autoplay: {
              delay: 3000,
            },
            speed: 600,
            slidesPerView: 1,
            loop:true,
            pagination: {
              el: '#product-types .pagination',
              type: 'bullets',
              clickable: true
            }
          });

        }
        //Load the Instagram Posts on the Homepage
        var itemCount = 4;

        $.ajax({
          type: "GET", 
          url: "https://api.instagram.com/v1/users/self/media/recent/?access_token=3132073757.1677ed0.5b476fb7317d417fa76ecb29e1c7bab2&count="+itemCount,
          crossDomain: true,
          success: function(response) {
              console.log(response);
      
              $.each(response.data, function(index, obj) {
                  console.log(obj.images.low_resolution.url);
                    $('#instagram-content > .row').append('<div class="col-md-3 instagram-post"><div class="row"><a href="' + obj.link + '" target="_blank"><div class="img-container" style="background-image:url(' + obj.images.low_resolution.url + ');"></div></a></div></div>');
              });
          },
      
          dataType: "jsonp" //set to JSONP, is a callback
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    // About us page, note the change from about-us to about_us.
    'woocommerce_checkout': {
      init: function() {
        // JavaScript to be fired on the about us page
        $('#email_permissions_field input').insertBefore('#email_permissions_field label');
        $('#email_permissions_field label').attr('for','email_permissions');

        $(document).change(function(){
          if($('select#user_pickup_location[data-method="local-pickup"]').is(':visible')){
            $('.shipping_address').hide();
            $('#ship-to-different-address-checkbox').prop('checked',false);
          }
        });

        if($(window).width() < 769){
          $('#order-totals-wrap').insertBefore('#customer-details');
        }

        var CurrentMethod;

        CurrentMethod = $('ul#shipping_method li input:checked').next('label').find('span.woocommerce-Price-amount').html();

        $('td#shipping-costs').html(CurrentMethod);

        $(document).change(function(){
          CurrentMethod = $('ul#shipping_method li input:checked').next('label').find('span.woocommerce-Price-amount').html();
          $('td#shipping-costs').html(CurrentMethod);
        });

        $(document).ajaxComplete(function(){
          CurrentMethod = $('ul#shipping_method li input:checked').next('label').find('span.woocommerce-Price-amount').html();
          $('td#shipping-costs').html(CurrentMethod);
        });

        $('ul#shipping_method li input').click(function(){
          CurrentMethod = $('ul#shipping_method li input:checked').next('label').find('span.woocommerce-Price-amount').html();
          $('td#shipping-costs').html(CurrentMethod);
        });

        /* $('#billing_postcode, #shipping_postcode').change(function(){
          $(document).ajaxComplete(function(){
            var shippingMethods = $('#inner-shipping-calc').children();
            $('#shipping_method-list').html(shippingMethods);
          });
        }); */

        $(document).on('click','#shipping_method label',function(){
          //alert('element clicked');
          $(document).ajaxComplete(function(){
            var selectedMethod = $('input.shipping_method:checked').attr('id');
            if (selectedMethod.indexOf('local_pickup') > 0 ){
              var currentVal = $('#user_pickup_location').val();
              if(currentVal === "" ){
                $('#pickup-district').val('');
                $('#user_pickup_location').val('');
                $('#pickup-modal').show();
              }
            } else {
              $('#selected-pickup-location').hide();
            }
          });
        });

        $(document).on('click','#select-location',function(){
          if($('#user_pickup_location').val() !== ''){
            var currentVal = $('#user_pickup_location').val();
            var currentStand = $('#user_pickup_location option[value="'+currentVal+'"]').attr('data-name');
            console.log(currentStand);
           /*  var currentSelectedLocation = $('user_pickup_location').val();
            var selectOption = $('#user_pickup_location option[value="'+currentSelectedLocation+'"]');
            console.log(selectOption); */
            $('#selected-pickup-location .item-location').html(currentStand);
            $('#pickup-modal').hide();
            $('#selected-pickup-location').show();
          }
        });

        $(document).on('click','#location-change',function(){
          $('#pickup-district').val('');
          $('#user_pickup_location').val('');
          $('#pickup-modal').show();
        });

        $('#pickup-modal #modal-close').click(function(){
          $('#pickup-modal').hide();
        });
      },
      finalize: function() {
        /* if(! $('body').hasClass('woocommerce-order-pay')){
          $(document).on('click','#place_order', function(e){
            var selectedMethod = $('input.shipping_method:checked').attr('id');
            var currentVal = $('#user_pickup_location').val();
            e.preventDefault();
            if($('input.shipping_method').length > 0 ){
              if (selectedMethod.indexOf('local_pickup') > 0 ){
                if(currentVal === "" ){
                 $('#pickup-district').val('');
                  $('#user_pickup_location').val('');
                  $('#pickup-modal').show();
                } else {
                  $('form.woocommerce-checkout').submit();
                }
              } else {
                $('form.woocommerce-checkout').submit();
              }
            } else {
              $('form.woocommerce-checkout').submit();
            }            
          });
        } */
      }
    },
    // About us page, note the change from about-us to about_us.
    'acessorios': {
      init: function() {
        //Getting the Parameters from the URL
        function getParameterByName(name, url) {
          if (!url) {url = window.location.href; }
          name = name.replace(/[\[\]]/g, '\\$&');
          var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
              results = regex.exec(url);
          if (!results) {return null;}
          if (!results[2]) {return '';}
          return decodeURIComponent(results[2].replace(/\+/g, ' '));
        }

        //Checking if filters exist and applying their values
        function getCurrentParameters(key , value , extra ){
          var newUrl;
            currentUrl = window.location.href;
            currentParams = getParameterByName(key);
            if(currentParams != null ){
              if(key === 'orderby'){
                if(currentParams === value ){
                  orderParam = getParameterByName('order');
                  newUrl = currentUrl.replace(orderParam, extra);
                } else {
                  orderParam = getParameterByName('order');
                  var tempUrl = currentUrl.replace(currentParams, value);
                  newUrl = tempUrl.replace(orderParam, extra);
                }
              } else {
                if(key === 'car_model'){
                  if(value === 'all'){
                    newUrl = currentUrl.replace( key+'='+currentParams , '');
                  } else {
                    if(currentParams === value){
                      newUrl = currentUrl.replace( key+'='+value , '');
                    } else {
                      newUrl = currentUrl.replace(currentParams , value);
                    } 
                  }
                } else {
                  if(currentParams === value){
                    newUrl = currentUrl.replace( key+'='+value , '');
                  } else {
                    newUrl = currentUrl.replace(currentParams , value);
                  } 
                }
              }              
            } else {
              if(currentUrl.indexOf("?") >= 0 ){
                if(key === 'orderby'){
                  newUrl = currentUrl+'&'+key+'='+value+'&order='+extra;
                } else {
                  newUrl = currentUrl+'&'+key+'='+value;
                }                
              } else {
                if(key === 'orderby'){
                  newUrl = currentUrl+'?'+key+'='+value+'&order='+extra;
                } else {
                  newUrl = currentUrl+'?'+key+'='+value;
                }
                
              }
              console.log(newUrl);
            }
            window.location.href = newUrl;
        }

        var activityTimeout;
               
        // No activity do something.
        function inActive(){
            var priceRangeLow = $('#min-amount').val();
            var priceRangeHigh = $('#max-amount').val();
            getCurrentParameters('range', priceRangeLow+','+priceRangeHigh, '');
        }

        function resetActive(){
          clearTimeout(activityTimeout);
          activityTimeout = setTimeout(inActive, 2000);
        } 
        
        // JavaScript to be fired on the about us page
        var minRange;
        var maxRange;
        var rangeParam = getParameterByName('range');
          if(rangeParam != null ){
            var res = rangeParam.split(",");
            //console.log(res);
            minRange = Math.floor(res[0]);
            maxRange = Math.ceil(res[1]);
          } else {
            minRange = Math.floor($('#min-amount').val());
            maxRange = Math.ceil($('#max-amount').val());
          }
        //Function to Operate the Price Range Slider
        var minPrice = Math.floor($('#min-amount').val());
        var maxPrice = Math.ceil($('#max-amount').val());
        $( "#slider-range" ).slider({
          range: true,
          min: minPrice,
          max: maxPrice,
          values: [ minRange , maxRange ],
          slide: function( event, ui ) {
              for (var i = 0; i < ui.values.length; ++i) {
                $("input.price-range-input[data-index=" + i + "]").val(ui.values[i]);
                resetActive();
              }
          }
          });

          $('#min-amount').val(minRange);
          $('#max-amount').val(maxRange);

          $("input.price-range-input").change(function() {
            var $this = $(this);
            $("#slider-range").slider("values", $this.data("index"), $this.val());
            resetActive();
        });        

        $('a.sort-item-btn').click(function(){
          $('a.sort-item-btn').removeClass('active');
          $(this).addClass('active');
          var btnKey = $(this).attr('data-sort');
          var btnValue = $(this).attr('data-order');
          var btnExtra = 'sorting';
          getCurrentParameters('orderby', btnKey, btnValue);
        });

        $('a.toggle-accordion').click(function(){
          var parentLi = $(this).closest('li.sidebar-model-group');
          if(parentLi.hasClass('active')){
            parentLi.removeClass('active');
          } else {
            $('li.sidebar-model-group').removeClass('active');
            parentLi.addClass('active');
          }
        });

        $('a.sidebar-model').click(function(){
          var btnValue;
          if($(this).attr('id') !== 'model-all'){
            $('a.sidebar-model').removeClass('active');
            $(this).addClass('active');
            btnValue = $(this).attr('data-model');
            getCurrentParameters('car_model', btnValue, '');  
          } else {
            if(!$(this).hasClass('active')){
              $('a.sidebar-model').removeClass('active');
              $(this).addClass('active');
              btnValue = $(this).attr('data-model');
              getCurrentParameters('car_model', btnValue, '');  
            }
          }
        });

        $('.sidebar-checkbox').on('change', function() {
          if($(this).prop('checked')){
              $(this).prop('checked', false);
          } else {
            $('.sidebar-checkbox').not(this).prop('checked', false);  
          }          
          var btnValue = $(this).attr('data-term');
          getCurrentParameters('product_cat', btnValue, '');
        });

        function findActiveFilters(){
          var orderbyParam = getParameterByName('orderby');
          var orderedParam = getParameterByName('order');
          var modelParam = getParameterByName('car_model');
          var catParam = getParameterByName('product_cat');
          //var rangeParam = getParameterByName('range');
          if(orderbyParam != null ){
            var activeSort = $('a[data-sort="'+orderbyParam+'"][data-order="'+orderedParam+'"]');
            activeSort.addClass('active');
            $('#current-sort').html(activeSort.html());
          }//
          if(modelParam != null ){
            $('#model-all').removeClass('active');
            $('a#model-'+modelParam).addClass('active');
            $('a#model-'+modelParam).closest('li.sidebar-model-group').addClass('active');
          }
          if(catParam != null ){
            $('#category-'+catParam).prop('checked', true);
          }
          
          
        }
        findActiveFilters();
        $('#model-all.active').click( function(){
            return false;
          }          
        );

        if($(window).width() < 769 ){
          $('a.mobile-toggle-filter').click(function(){
            var filterTarget = $(this).attr('data-target');
            if(filterTarget === 'group-sort'){
              if($('body').hasClass('store-filters')){
                $('body').removeClass('store-filters');
                $('body').addClass(filterTarget);
              } else {
                $('body').addClass(filterTarget);
              }
            } else {
              if($('body').hasClass('group-sort')){
                $('body').removeClass('group-sort');
                $('body').addClass(filterTarget);
              } else {
                $('body').addClass(filterTarget);
              }
            }
          });

          $('.mobile-filters-close, #nav-toggle-button').click(function(){
              $('body').removeClass('store-filters');
              $('body').removeClass('group-sort');
          });

          
          $('#nav-toggle-button').click(function(){
            console.log('should scroll');
            window.scrollTo(0, 0);
          });
        }

      }  
    },
    'merchandising': {
      init: function() {
        // JavaScript to be fired on the about us page

        //Getting the Parameters from the URL
        function getParameterByName(name, url) {
          if (!url) {url = window.location.href;}
          name = name.replace(/[\[\]]/g, '\\$&');
          var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
              results = regex.exec(url);
          if (!results) {return null;}
          if (!results[2]) {return '';}
          return decodeURIComponent(results[2].replace(/\+/g, ' '));
        }

        //Checking if filters exist and applying their values
        function getCurrentParameters(key , value , extra ){
          currentUrl = window.location.href;
          currentParams = getParameterByName(key);
          var newUrl;
          if(currentParams != null ){
            if(key === 'orderby'){
              if(currentParams === value ){
                orderParam = getParameterByName('order');
                newUrl = currentUrl.replace(orderParam, extra);
              } else {
                orderParam = getParameterByName('order');
                var tempUrl = currentUrl.replace(currentParams, value);
                newUrl = tempUrl.replace(orderParam, extra);
              }
            } else {
              if(currentParams === value ){
                newUrl = currentUrl.replace( key+'='+value , '');
              } else {
                newUrl = currentUrl.replace(currentParams , value);
              }
            }              
          } else {
            if(currentUrl.indexOf("?") >= 0 ){
              if(key === 'orderby'){
                newUrl = currentUrl+'&'+key+'='+value+'&order='+extra;
              } else {
                newUrl = currentUrl+'&'+key+'='+value;
              }                
            } else {
              if(key === 'orderby'){
                newUrl = currentUrl+'?'+key+'='+value+'&order='+extra;
              } else {
                newUrl = currentUrl+'?'+key+'='+value;
              }
              
            }
            console.log(newUrl);
          }
          window.location.href = newUrl;
      }

        var activityTimeout;
                
        // No activity do something.
        function inActive(){
            var priceRangeLow = $('#min-amount').val();
            var priceRangeHigh = $('#max-amount').val();
            getCurrentParameters('range', priceRangeLow+','+priceRangeHigh, '');
        }

        function resetActive(){
          clearTimeout(activityTimeout);
          activityTimeout = setTimeout(inActive, 2000);
        }
  
        var rangeParam = getParameterByName('range');
          var minRange;
          var maxRange;
          if(rangeParam != null ){
            var res = rangeParam.split(",");
            //console.log(res);
            minRange = Math.floor(res[0]);
            maxRange = Math.ceil(res[1]);
          } else {
            minRange = Math.floor($('#min-amount').val());
            maxRange = Math.ceil($('#max-amount').val());
          }
        //Function to Operate the Price Range Slider
        var minPrice = Math.floor($('#min-amount').val());
        var maxPrice = Math.ceil($('#max-amount').val());
        $( "#slider-range" ).slider({
          range: true,
          min: minPrice,
          max: maxPrice,
          values: [ minRange , maxRange ],
          slide: function( event, ui ) {
              for (var i = 0; i < ui.values.length; ++i) {
                $("input.price-range-input[data-index=" + i + "]").val(ui.values[i]);
                resetActive();
              }
          }
          });
    
          $('#min-amount').val(minRange);
          $('#max-amount').val(maxRange);
    
          $("input.price-range-input").change(function() {
            var $this = $(this);
            $("#slider-range").slider("values", $this.data("index"), $this.val());
            resetActive();
        });
    
        $('a.sort-item-btn').click(function(){
          $('a.sort-item-btn').removeClass('active');
          $(this).addClass('active');
          var btnKey = $(this).attr('data-sort');
          var btnValue = $(this).attr('data-order');
          var btnExtra = 'sorting';
          getCurrentParameters('orderby', btnKey, btnValue);
        });
    
        $('.sidebar-gama').on('change', function() {
          if($(this).prop('checked')){
            $(this).prop('checked', false);
          } else {
            $('.sidebar-gama').not(this).prop('checked', false);  
          }
          var btnValue = $(this).attr('data-term');
          getCurrentParameters('gama', btnValue, '');
        });
    
        $('.sidebar-checkbox').on('change', function() {
          if($(this).prop('checked')){
              $(this).prop('checked', false);
          } else {
            $('.sidebar-checkbox').not(this).prop('checked', false);  
          }          
          var btnValue = $(this).attr('data-term');
          getCurrentParameters('product_cat', btnValue, '');
        });
    
        function findActiveFilters(){
          var orderbyParam = getParameterByName('orderby');
          var orderedParam = getParameterByName('order');
          var gamaParam = getParameterByName('gama');
          var catParam = getParameterByName('product_cat');
          //var rangeParam = getParameterByName('range');
          if(orderbyParam != null ){
            var activeSort = $('a[data-sort="'+orderbyParam+'"][data-order="'+orderedParam+'"]');
            activeSort.addClass('active');
            $('#current-sort').html(activeSort.html());
          }//
          if(gamaParam != null ){
            $('#gama-'+gamaParam).prop('checked', true);
          }
          if(catParam != null ){
            $('#category-'+catParam).prop('checked', true);
          }
          
        }
        findActiveFilters();

        if($(window).width() < 769 ){
          $('a.mobile-toggle-filter').click(function(){
            var filterTarget = $(this).attr('data-target');
            if(filterTarget === 'group-sort'){
              if($('body').hasClass('store-filters')){
                $('body').removeClass('store-filters');
                $('body').addClass(filterTarget);
              } else {
                $('body').addClass(filterTarget);
              }
            } else {
              if($('body').hasClass('group-sort')){
                $('body').removeClass('group-sort');
                $('body').addClass(filterTarget);
              } else {
                $('body').addClass(filterTarget);
              }
            }
          });

          $('.mobile-filters-close, #nav-toggle-button').click(function(){
              $('body').removeClass('store-filters');
              $('body').removeClass('group-sort');
          });

          
          $('#nav-toggle-button').click(function(){
            console.log('should scroll');
            window.scrollTo(0, 0);
          });
        }

      }
    },
    // About us page, note the change from about-us to about_us.
    'single_product': {
      init: function() {
        // JavaScript to be fired on the single product page
        $(document).on('click','.number-change',function(){
          var targetInput = $(this).attr('data-target');
          $('button[name="update_cart"]' ).removeProp( 'disabled');
          if($(this).hasClass('input-decrease')){
            document.getElementById(targetInput).stepDown(1);
          } else {
            document.getElementById(targetInput).stepUp(1);
          }
        });

        $('#share-toggle').click(function(){
          $('html, body').toggleClass('active-share');
        });

        $('#modal-close').click(function(){
          $('html, body').toggleClass('active-share');
        });

        $('#notice-close').click(function(){
          $('.woocommerce-notices-wrapper').html('');
        });

        var thumbsSwiper = new Swiper('#product-thumbs-slider', {
          autoplay: false,
          speed: 600,
          slidesPerView: 4,
          loop:false, 
          slideToClickedSlide: false,
          navigation: {
            nextEl: '#product-thumbs-slider .swiper-btn-next',
            prevEl: '#product-thumbs-slider .swiper-btn-prev',
          }
        });

        var singleSwiper = new Swiper('#product-gallery-slider', {
          autoplay: false,
          speed: 600,
          slidesPerView: 1,
          loop:false,
          navigation: {
            nextEl: '#product-gallery-slider .swiper-btn-next',
            prevEl: '#product-gallery-slider .swiper-btn-prev',
          },
          thumbs: {
            swiper: thumbsSwiper,
          }
        });

        $('a.magnify').on('click', function(){
            $('#zoom-container').html('');
            $(this).closest('.product-image-large').find('img').ezPlus({
              zoomType: 'inner',
              zoomContainerAppendTo : '#zoom-container',
              responsive: true
            });
        });

        $('.ZoomContainer').on('click',function(){
          $('#zoom-container').html('');
        });

        if($(window).width() < 769){
          $('#nav-toggle-button').click(function(){
            $('#zoom-container').html('');
          });

          $('span.share-link-wrap').appendTo('#share-container'); 
        }
      }
    },
    // About us page, note the change from about-us to about_us.
    'woocommerce_cart': {
      init: function() {
        // JavaScript to be fired on the single product page
        $(document).on('click','.number-change',function(){
          var targetInput = $(this).attr('data-target');
          $('button[name="update_cart"]' ).removeProp( 'disabled');
          if($(this).hasClass('input-decrease')){
            document.getElementById(targetInput).stepDown(1);
          } else {
            document.getElementById(targetInput).stepUp(1);
          }
        }); 
        
        $('#modal-close').click(function(){
          $('#notice-modal').hide();
        });
      }
    },
    'woocommerce_account': {
      finalize: function(){
        if($(window).width() < 769){
          $('th.woocommerce-orders-table__header.woocommerce-orders-table__header-order-number').html('As minhas compras');
        }

        $('a#lost-pass-toggle').click(function(){
          $('#lostpass-modal').show();
        });

        $('#modal-close').click(function(){
          $('#lostpass-modal').hide();
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'single_homologations': {
      init: function() {
        // JavaScript to be fired on the single product page
        $('input.variation-checkbox').change(function(){
          var newVal = $(this).val();
          var currentSelect = $(this).attr('data-select');
          $('#'+currentSelect).val(newVal).change();
          $(this).prop('checked', true);
          $('input.variation-checkbox').not(this).prop('checked', false);
          $('.variation-price').hide();
          $('.variation-price[data-price="'+newVal+'"]').show();
        });

        $('figure a').click(function(){
          return false;
        });

        var input = document.querySelectorAll( '.document-btn-wrap input' );
        var label	 = $('#file-label'),
            labelVal = label.html();

        $(input).on( 'change', function( e )
          {
            var fileName = '';
            if( this.files && this.files.length > 1 )
              {fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );}
            else
              {fileName = e.target.value.split( '\\' ).pop();}

            if( fileName )
              {label.html(fileName);}
            else
             { label.html(labelVal);}
          });
          
      }
    },
    // About us page, note the change from about-us to about_us.
    'single_acessories': {
      init: function() {
        // JavaScript to be fired on the single product page
        $(document).change(function(){
          var count = 0;
          var totalOptions = $('select').length;
          
          $('select').each(function(){
            if($(this).val() !== ''){
              count ++;
            }
          });
          
          if (count === totalOptions){
            var singleVariationContent = $('.woocommerce-variation.single_variation').html();
            //console.log(singleVariationContent);
   
            if (singleVariationContent !== '' ){
              var activePrice = $('.woocommerce-variation.single_variation .woocommerce-Price-amount').html();
              console.log(activePrice+' / '+activePrice.length);
              $('.variation-updated-price').html(activePrice);
            } else {
              console.log('there is no choice yet');
            }
            $('.global-price').hide();
            $('.variation-updated-price').show();
          } else {
            $('.global-price').show();
            $('.variation-updated-price').hide();
          }         
          
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  //Homologation form Element
  var form = $('.cart');

  $.validator.addMethod('filesize', function (value, element, param) {
      return this.optional(element) || (element.files[0].size <= param);
  }, 'File size must be less than {0}');

  $.validator.addMethod("license", function (value, element) {
    return this.optional(element) || /^[A-Za-z0-9]{2} ?- ?[A-Za-z0-9]{2} ?- ?[A-Za-z0-9]{2}$/.test(value);
  }, "Por favor, insira uma matrícula válida");

  $(form).validate({
    rules : {
      homologation_model : {
        required: true
      },
      homologation_chassis : {
        required: true
      },
      homologation_plate : {
        required : true,
        license : true
      },
      homologation_vin : {
        required : true
      },
      required_measurements : {
        required : true
      },
      uploaded_file : {
        required : true,
        accept:"jpg,png,jpeg,gif,pdf",
        filesize: 2097152,  
      },
      homologation_accept : {
        required : true,
      }
    },
    messages: {
      homologation_model : 'Preencha o Modelo do Veículo',
      homologation_chassis : 'Preencha o nº do Quadro',
      homologation_plate : 'Preencha a Matrícula do veículo <span class="format">(Formato **-**-**)</span>',
      homologation_vin : 'Preencha a Chapa VIN',
      uploaded_file : 'Insira um ficheiro do tipo imagem ou pdf, até 2MB.',
      required_measurements : 'Insira as medidas pretendidas',
      homologation_accept : 'Deve concordar com os termos da Política de Privacidade.'
    },
    ignore: ".ignore, :hidden, .qty",
    errorPlacement: function(error, element) {
      if(element.attr("name") === "uploaded_file" || element.attr("name") === "homologation_accept") {
        error.appendTo( element.closest(".input-inner-wrap").find("span.error-location") );
      } else {
        error.insertAfter(element);
      }
    }
  });

  $(form).on('submit',function(e){
    //e.preventDefault();
    if($(form).valid()){
      console.log('valid form');
      return true;
    } else {
      e.preventDefault();
      console.log('form invalid');
    }
    //saveForm();
  });


})(jQuery); // Fully reference jQuery after this point.
